<template>
  <div class="case-conent">
    <div class="hd">
      <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini"  plain round>返回</el-button></div>
      知识内容测评
    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div>图书名称：{{bookName}}</div>
      <div class="table">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="listData"
        >
          <el-table-column prop="sort" label="序号"  header-align="center" width="50"></el-table-column>
          <el-table-column prop="folderName" label="课时名称" header-align="center"></el-table-column>
          <el-table-column prop="exerciseName" label="练习名称" header-align="center"></el-table-column>
          <el-table-column prop="createTime" label="作业提交时间" header-align="center"></el-table-column>
          <el-table-column prop="score" width="80" label="成绩" header-align="center"></el-table-column>
          <el-table-column prop="contents" label="知识内容总数" header-align="center"></el-table-column>
          <el-table-column prop="qus" label="总题数" header-align="center">
            <template slot-scope="scope">
              <div class="dat1">{{scope.row.qus}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="errors" label="未掌握题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.errors}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="基本掌握题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.rights}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="掌握较好题数" header-align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">{{scope.row.goods}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" header-align="center">
            <template #default="scope">
              <div v-if="scope.row.status==1">
                <div><el-button @click="tosKtentKnowledgeList(scope)" type="primary" size="mini" plain>知识内容掌握情况</el-button></div>
                <div class="btnBOx"><el-button @click="toSunit(scope)" type="primary" size="mini" plain>批改结果</el-button></div>


              </div>
              <div v-else>--</div>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" v-if="listData !=0">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {qfindScreenSubjectObj,queryContentBookListObj,yContentExerciseListObj} from '@/api/evaluation'
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      SubjectData: [],
      pageSize:null,
      pageCurrent:null,
      total:null,
      evaluation:{
        subjectId:null
      },
      listData:[

      ],
      bookName:''

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.bookId=this.$route.query.bookId;
    this.bookName=this.$route.query.bookName;
    this.stu = this.$route.query.stu;
    this.findScreenSubject()
    this.queryContentBookList()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    //查询筛选的学科列表
    findScreenSubject() {
      qfindScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SubjectData = res.data
      })
    },
    // 学科
    subjectChange($event){
      sessionStorage.setItem('subjectId',$event)
      this.queryContentBookList()
    },
    // 清除学科
    subjectClear() {
      this.evaluation.subjectId = ''
      sessionStorage.removeItem('subjectId');
      this.queryContentBookList()
    },



    //查询列表
    queryContentBookList() {
      let obj = {
        bookId:this.bookId,
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      yContentExerciseListObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.data.records
          this.pageSize = res.data.data.size;
          this.currentPage = res.data.data.current;
          this.total = res.data.data.total
        } else {
          this.listData = null
          this.pageSize = null;
          this.currentPage = null;
          this.total = null
        }

      })
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.queryContentBookList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.queryContentBookList();
    },
    // 去知识点
    tosKtentKnowledgeList(scope) {
      this.$router.push({
        path: "/mainb/tentKnowledgeList",
        query: {
          EducationCase:this.EducationCase,
          testPaperId:scope.row.testPaperId,
          bookName:this.bookName,
          clickFlag:2,
          stu:this.stu
        }
      });
    },
    // 去批改结果
    toSunit(scope) {
      this.$router.push({
        path: "/mainb/unit",
        query: {
          EducationCase:this.EducationCase,
          testPaperId:scope.row.testPaperId,
          clickFlag:2,
          stu:this.stu,
          bookName:this.bookName,
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;
   .dat1 {
     width: 60px;
   }
  .btnBOx {
    width: 100%;
    margin-top: 10px;
  }
  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
      position: absolute;
      justify-content: flex-start;
      padding-left: 20px;
      box-sizing: border-box;
      .back-btn {
        border: 1px solid #c4e7ff;padding: 0px 20px; height:32px;line-height: 32px; background-color: #37AEFF; color:#c4e7ff
      }
    }
  }

  .table-content {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
